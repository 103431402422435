import React from 'react'
import { Stack, Main } from '@layout'
import { Box, Flex } from 'theme-ui'
import CardList from '@components/CardList'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import Categories from '@widgets/Categories'
import Tags from '@widgets/Tags'
import NewsletterExpanded from '@widgets/NewsletterExpanded'
import PageTitle from '@components/PageTitle'
// import BannerHorizontal from '@widgets/BannerHorizontal'
// import BannerVertical from '@widgets/BannerVertical'
import { useBlogCategories, useBlogTags } from '@helpers-blog'
import useSiteMetadata from '@helpers/useSiteMetadata'

const styles = {
  imageWrapper: {
    borderRadius: `default`,
    overflow: `hidden`,
    position: `relative`
  },
  button: {
    display: [`none`, `block`],
    position: `absolute`,
    bottom: 4,
    right: 4
  },
  grid: {
    flexWrap: [`wrap`, `wrap`, `nowrap`],
    ' > div + div': {
      ml: [0, 0, 5]
    }
  },
  column: {
    flex: `auto`,
    flexBasis: [`full`, `full`, `1/2`]
  }
}

const Posts = ({
  data: { posts = {}, featuredPosts = {}, recentPosts = {} },
  ...props
}) => {
  const { pageContext: { services = {} } = {} } = props
  const categories = useBlogCategories()
  const tags = useBlogTags()
  const { topic } = useSiteMetadata()

  return (
    <>
      <Seo title={`${topic} Franchise Opportunities`} />
      {/* <Divider />
      <Stack effectProps={{ effect: false }}>
        <Categories categories={categories} variant='horizontal' omitTitle />
      </Stack> */}
      <Divider />
      <Stack effectProps={{ effect: false }}>        
        <Main>
          <PageTitle
            header={`${topic} Franchise Opportunities`}
            subheader={`We can help you choose the right Semi-Absentee, Passive Income ${topic} Franchise`}
          />
          <CardList
            nodes={featuredPosts.nodes}
            limit={3}
            variant='horizontal-cover'
            slider
            fade
            controlPosition='over'
            loading='eager'
            omitCategory
            omitAuthor
          />
          <Divider space={2} />
          <CardList
            nodes={recentPosts.nodes}
            limit={4}
            columns={[1, 2]}
            variant='horizontal-aside'
            loading='eager'
            omitAuthor
          />
        </Main>
        {/* <Sidebar sx={{ pl: `3`, flexBasis: `1/4` }}>
          <BannerVertical />
        </Sidebar> */}
      </Stack>
      <Divider space={5} />
      {posts.group.length &&
        posts.group.map((group, index) => (
          <React.Fragment key={`${group.categoryName}.list`}>
           
            <Stack
              title={group.categoryName}
              titleLink={group.nodes[0].category.slug}
            >
              <Main>
                <CardList
                  nodes={group.nodes}
                  limit={3}
                  columns={[1, 1, 1, 3]}
                  variant={[
                    'horizontal-md',
                    'horizontal',
                    'horizontal',
                    'vertical',
                  ]}
                />
              </Main>
            </Stack>
           
            {index === 0 && (
              <>
                <Divider />
                <Stack effectProps={{ effect: false }}>
                  {/* <BannerHorizontal /> */}
                </Stack>
              </>
            )}
            {index !== posts.group.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      <Stack>
        <Main>
          {services.mailchimp && (
            <>
              <Divider space={5} />
              <NewsletterExpanded />
            </>
          )}
        </Main>
      </Stack>
      <Divider space={4} />
      <Stack effectProps={{ effect: false }}>
        <Flex sx={styles.grid}>
          <Box sx={styles.column}>
            <Categories categories={categories} variant='vertical' />
          </Box>
          <Box sx={styles.column}>
            <Tags tags={tags} />
          </Box>
        </Flex>
      </Stack>
    </>
  )
}

export default Posts
